import React from "react"

export default function Form() {
  return (
    <form
      action="https://getform.io/f/81278f4c-28db-40bb-a056-4a8f8af5aa48"
      method="POST"
    >
      <div className=" overflow-hidden sm:rounded-md px-2">
        <div className="">
          <div className="grid grid-cols-6 gap-6 w-full">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Förnamn
              </label>
              <input
                required
                type="text"
                name="first-name"
                id="first-name"
                className="mt-1 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-200 rounded-md px-4 "
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Efternamn
              </label>
              <input
                required
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="mt-1 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-200 rounded-md px-4"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Epost
              </label>
              <input
                required
                type="text"
                name="email-address"
                id="email-address"
                autoComplete="email"
                className="mt-1 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-200 rounded-md px-4"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Bjuden av
              </label>
              <input
                type="text"
                name="invited-by"
                id="invited-by"
                autoComplete="email"
                className="mt-1 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-200 rounded-md px-4"
              />
            </div>
            <div className="flex flex-row col-span-6 w-full">
              <div className="flex flex-none items-center h-5">
                <input
                  id="plusone"
                  name="plusone"
                  type="checkbox"
                  className="focus:ring-primary h-4 w-4 text-primary border-gray-200 rounded"
                />
              </div>
              <div className="ml-3  text-sm w-full ">
                <label
                  htmlFor="plusone"
                  className="block w-full flex-none font-medium text-gray-700"
                >
                  Jag tar med en vän
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="my-8">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  text-white bg-primary hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Skicka
          </button>
        </div>
      </div>
    </form>
  )
}
