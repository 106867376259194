import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form"

const IndexPage = () => (
  <Layout>
    <SEO title="Inflyttningsfest - Studio 73A" />
    <div className="flex flex-col px-4 md:px-8  py-16 min-h-screen max-w-4xl mx-auto">
      <div className="flex-1  min-h-screen items-center justify-center ">
        <div className="  flex flex-col gap-8 justify-center h-full">
          <header>
            <h1 className="text-7xl font-bold tracking-tight">
              Studio 73A <br />
              Afterwork
            </h1>
            <h2 className="text-7xl"></h2>
          </header>{" "}
          <div className="text-xl space-y-4">
            <p>
              Studio Monbijou har flyttat till frihamnen och bytt namn till
              Studio 73A.
            </p>
            <p>
              Med anledning av detta tänkte vi det var dags att bjuda in till en
              after work.
            </p>
            <p>
              Kom förbi mellan 16-20,{" "}
              <span className="font-bold">torsdagen den 16 Juni</span>. Vi
              bjuder på något att dricka och lite tilltugg. Vi finns på{" "}
              <Link to="https://www.google.com/maps/place/Jörgen+Kocksgatan+73,+211+20+Malmö/data=!4m2!3m1!1s0x4653a3e862f7aba5:0x9185d56ed3e5d83a?sa=X&ved=2ahUKEwiDs5zyrY74AhWmR_EDHdrbCgcQ8gF6BAgSEAE">
                Jörgen Kocksgatan 73A
              </Link>
              .
            </p>
            <p>
              Fyll i formuläret nedan så vi vet om du kan komma. Ta gärna med en
              vän!
            </p>
            <p>
              Studio 73A är: Marcus Lawett, Jens Nordström, Lina Arvidsson,
              Johan Sundell, Miriam Preis, Olle Burlin, Ian Bennet, Oskar Krona,
              Jonathan Rönnberg, Magnus Pong, Joakim Olsson
            </p>
            <p>Vi ses!</p>
          </div>
          <div>
            <Form />
          </div>
        </div>

        <div className="mt-8">
          <div className="flex-1 grid grid-cols-1 gap-8">
            <div className="bg-white p-2 md:p-4">
              <StaticImage
                src="../images/ovanvaning.jpg"
                className=" h-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
